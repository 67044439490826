<template>
  <docs-layout> 
    <div class="space-y-4 pb-10 w-full text-left">
      <h1 class="text-3xl font-bold"></h1>
      <p>By default, the dropdown will use button as toggle and have empty content. You are free to add content to dropdown</p>
      <asom-dropdown>
        <div class="py1 w-40" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Account settings</a>
          <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Support</a>
          <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">License</a>
          <form method="POST" action="#">
            <button type="submit" class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
              Sign out
            </button>
          </form>
        </div>
      </asom-dropdown>
      <p>Toggle can be customize via slot "toggle". Here is an example of using icon button to toggle.</p>
      <asom-dropdown>
        <template #toggle="{ click }">
          <button @click="click">
            <asom-icon icon="user" />
          </button>
        </template>
        <div class="py1 w-40" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Account settings</a>
          <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Support</a>
          <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">License</a>
          <form method="POST" action="#">
            <button type="submit" class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
              Sign out
            </button>
          </form>
        </div>
      </asom-dropdown>
      <p>Dropdown content can be positioned</p>
      <div class="flex space-x-4">
        <asom-dropdown toggle-text="Bottom left" position="bottom-left">
          <p class="m-4 w-48">This goes bottom-left</p>
        </asom-dropdown>
        <asom-dropdown toggle-text="Bottom right" position="bottom-right">
          <p class="m-4 w-48">This goes bottom-right</p>
        </asom-dropdown>
      </div>
    </div>
  </docs-layout>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout.vue'
export default {
  components: {
    DocsLayout,
  },
}
</script>